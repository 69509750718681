import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

interface Props {
  data: {
    allMarkdownRemark: any
    site: {
      siteMetadata: {
        title: string
        siteUrl: string
        social: { twitter: string }
      }
    }
  }
}

const BlogIndex = ({ data }: Props) => {
  const {
    title: siteTitle,
    siteUrl,
    social: { twitter },
  } = data.site.siteMetadata
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout title={siteTitle} twitter={twitter}>
      <SEO title="All posts" url={siteUrl} />
      <ul>
        {posts.map(({ node }: any) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <li
              key={node.fields.slug}
              className="flex content-end flex-wrap pb-4"
            >
              <Link to={node.fields.slug}>
                {title}
                <small className="ml-2">
                  <time>{node.frontmatter.date}</time>
                </small>
              </Link>
            </li>
          )
        })}
      </ul>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        social {
          twitter
        }
      }
    }
    allMarkdownRemark(
      filter: { fields: { draft: { eq: false } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
